import React from 'react'

import Link from 'next/link'

import H1 from 'components/Text/H1'
import P from 'components/Text/P'
import fontTheme from 'styles/fonts'
import GlobalStyles from 'styles/global'

const PageNotFound = () => {
  return (
    <>
      <GlobalStyles />

      {/* eslint-disable-next-line react/no-unknown-property */}
      <style jsx global>
        {fontTheme}
      </style>

      <div style={{
        alignItems: 'center',
        height: '100vh',
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        width: '100vw',
      }}>
        <H1 className={'center darkblue'}>
          Page Missing
        </H1>

        <P>
          {'We can\'t find the page you are looking for! Please head back '}
          <Link href={'/'}>
            <span
              className={'lightblue'}
              style={{ textDecoration: 'underline' }}
            >
              home
            </span>
          </Link>.
        </P>
      </div>
    </>
  )
}

export default PageNotFound
